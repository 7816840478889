import './Interests.css'

const Interests = () => {
    return (
        <div className="interests">
            <h1>Interests</h1>
            <p>Reading | Football | Travel | Sauna</p>
        </div>
    )
}


export default Interests